// author:马云阳；description:众惠-公募-转入
<template>
    <section class="PublicFundProcess">
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont">
            <a @click="toGos" class="title_">
            <span class="iconfont">&#xe665;</span> {{funddata.fundName}}
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
            <div class="card_">
              <div class="titles_">卖出基金</div>
              <div @click="toGos" class="right_btns">
                <span class="iconfont icon-trade-exchange" style="color:#DEB87B;margin-right:6px;"></span>
                <a>更换产品</a></div>
              <div class="card_new">
                <div class="card_l">
                  <div class="right_ti">
                    <div class="nema_ti">
                      <span class="num">{{funddata.fundCode}}</span>
                      <span class="name_">{{funddata.fundName}}</span>
                    </div>
                    <div class="tags">
                  <div class="tag_">
                     {{sys_fund_riskleveldata[funddata.ofundRisklevel]||'--'}}
                  </div>
                  <div class="tag_">
                    {{sys_fund_typedata[funddata.ofundType]||'--'
                    }}
                  </div>
                  <div class="tag_">{{sys_fund_share_typedata[funddata.shareType]||'--'}}</div>
                </div>
                  </div>
                  <div class="r_nums">
                    当前卡可卖出<span class="price_nums">{{shares}}</span><span class="fen_S">份</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="play_type">
              <div class="ti_">卖出至</div>
              <div class="bank_card">
                <div v-if="showflag" @click="bankswith(1)" :class="bankindex==1?'bank_listac':''" class="bank_list">
                  <img v-if="bankindex!=1" src="@/static/img/trade_flow/trade-huijinbao-unselect.png" alt="" class="l_imgs">
                  <img v-if="bankindex==1" src="@/static/img/trade_flow/trade-huijinbao-select.png" alt="" class="l_imgs">
                  <div :class="bankindex==1?'c_nameac':''" class="c_name">惠金宝</div>
                  <div class="r_share">当前可卖出 <span class="color_">{{xjbprice||0}}份</span></div>
                  <div v-if="bankindex==1" class="right_true"><span>✓</span></div>
                </div>
                <div v-if="bankindex==1" class="bankcard_">
                  <div :class="xjbindex==k?'backs_ac':''" @click="getcardxjb(items,k)" v-for="(items,k) in xjbList" :key="k"
                    class="banks_">
                    <div class="top_ban">
                      <img :src="items.logoUrl" alt="">
                      <span class="bank_name">{{items.bankName||''}}</span>
                    </div>
                    <div class="cardnum_">{{items.bankAccount||''}}</div>
                    <div v-if="xjbindex==k" class="right_true"><span>✓</span></div>
                  </div>
                  <!-- <a @click="topagebank" class="addbank_">
                    <div style="margin-top:4px;transform: rotate(45deg);margin-right:6px"><span
                        style="font-size:18px;color:#bdc0cb;" class="iconfont">&#xe6ab;</span></div>
                    <span class="add_">添加银行</span>
                  </a> -->
                </div>
                <div @click="bankswith(2)" :class="bankindex==2?'bank_listac':''" class="bank_list">
                  <img v-if="bankindex!=2" src="@/static/img/trade_flow/trade-bankpay-unselect.png" alt="" class="l_imgs">
                  <img v-if="bankindex==2" src="@/static/img/trade_flow/trade-bankpay-select.png" alt="" class="l_imgs">
                  <div :class="bankindex==2?'c_nameac':''" class="c_name">快捷支付</div>
                  <!-- 单笔限额 <span class="color_">3万</span> -->
                  <div class="r_share">当前卡可卖出 <span class="color_">{{bankshare||'0'}}份</span></div>
                  <div v-if="bankindex==2" class="right_true"><span>✓</span></div>
                </div>
                <div v-if="bankindex==2" class="bankcard_">
                  <div :class="cardindex==i?'backs_ac':''" @click="getcard(item,i)" v-for="(item,i) in play" :key="i" class="banks_">
                    <div class="top_ban">
                      <img :src="item.logoUrl" alt="">
                      <span class="bank_name">{{item.bankName}}</span>
                    </div>
                    <div class="cardnum_">{{item.bankAccount}}</div>
                    <div v-if="cardindex==i" class="right_true"><span>✓</span></div>
                  </div>
                  
                </div>
              </div>
              <div class="input_share">
                <div class="ti_">卖出份额</div>
                <div class="input_box">
                  <div class="input_">
                    <input style="width:90%;height:100%;"  @blur="blurs($event)" @keydown="clerInput" v-model="numprice" class="input_s" type="number" placeholder="请输入份额">
                    <a @click.stop="long" style="height:46px;line-height:46px;width:10%;"  class="long">全部</a>
                  </div>
                  <span class="fen_s">&emsp;份</span>
                  <a @click="showrule" class="rules_btn">交易规则</a>
                </div>
                <div v-if="showti" class="red_ti">
                  {{titxt}}
                </div>
                <div class="list_nums_">
                  <a v-for="(item,i) in algorithm" :key="i" :class="algorithindex===i?'btn_sac':''" @click.stop="algorithnum(i)" class="btn_s">{{item.txt}}</a>
                </div>
                <div class="rule_txt">
                  <div class="rule_ti">交易须知:</div>
                  <div class="rule_">卖出费率 <span>{{rate||0.00}}</span>,预估卖出费用:{{costPrice}}，卖出确认日：T&emsp;+&emsp;{{matchObj.buyConfirmDate||''}}&emsp;预计卖出日期：{{matchObj.fundTransferDate||'--'}}</div>
                </div>
                <div class="rule_file">
                  <div class="file_ti">
                    <span style="margin-right:6px;color:#CE9B63" class="iconfont">&#xe670;</span>
                    <div class="file_txt_ti">交易遵守 <span class="file_btn">同卡进出</span>规则，当前不支持支付方式选择 </div>
                  </div>
                  <div class="files_">
                    <input  v-model="checkbool" style="width:10px;height:10px;margin-top: 3px;" type="checkbox">
                    <div class="files_btns">
                      阅读并同意
                      <a  @click="agree1&&agree1.length>0?toPdf(agree1):treatyerr()" class="tobtn_">《产品概要》、</a>
                      <a  @click="agree2&&agree2.length>0?toPdf(agree2):treatyerr()" class="tobtn_">《基金合同》、</a>
                      <a  @click="agree3&&agree3.length>0?toPdf(agree3):treatyerr()" class="tobtn_">《招募说明书》、</a>
                      <a  @click="agree4&&agree4.length>0?toPdf(agree4):treatyerr()" class="tobtn_">《风险提示书》、</a>
                      <a  @click="agree5&&agree5.length>0?toPdf(agree5):treatyerr()" class="tobtn_">《客户维护费揭示书》</a>
                    </div>
                  </div>
                  <div class="foot_btns">
                    <div class="left_num">
                      <div class="num_ti">待卖出</div>
                      <div class="nums_">{{Number(numprice)||0}}<span class="fen_">份</span>
                      </div>
                    </div>
                    <a @click="topeges" class="right_btn">下一步</a>
                  </div>
                </div>
              </div>
           </div>
        </div>
      <div class="fund-rule">
        <fund-detail-rule ref="fundRule" :fundCode="fundCodes"/>
      </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import { baseInfo } from "@/api/mysetcast";
import { queryUserShare, queryBankCard, enableShare } from "@/api/outfund";
import { rateMagMatch } from "@/api/addfund";
import FundDetailRule from "@/views/fund-market/components/FundDetailRule.vue";
import { getDicts } from "@/api/dict.js";
import { protPage } from "@/api/other/index.js";
import { findXjbAccoList ,findXjbBalance} from "@/api/getMoney.js";
export default {
  name: "SellOutFund",
  components: { ProgressBar, FundDetailRule },
  data() {
    return {
      fundCodes:'',
      side: [
        { title: "选择卖出基金", type: 1 },
        { title: "填写卖出信息", type: 2 },
        { title: "输入卖出密码", type: 0 },
        { title: "交易结果", type: 0 }
      ],
      play: [],
      algorithm: [
        { txt: "总额的1/4", id: 1 },
        { txt: "总额的1/3", id: 2 },
        { txt: "总额的1/2", id: 3 },
        { txt: "全部持仓", id: 4 }
      ],
      algorithindex: 0, //计算方式
      shares: 0,
      bankindex: 1, //默认是索引第一个
      tag: ["中高风险", "混合型", "前端收费"],
      checkbool: false,
      numprice: null,
      showti: false,
      titxt: '',
      cardindex: 0,
      xjbindex:0,
      xjbList:[],
      banklist: {},
      palybank: [],
      funddata: {}, //数据仓库
      matchObj: {},
      xjbprice: "",
      sys_fund_riskleveldata: {}, //产品风险等级
      sys_fund_typedata: {}, //基金类型
      sys_fund_share_typedata: {}, //产品份额
      bankshare: "",
      /** 基金协议 */
      agree1: "",
      agree2: "",
      agree3: "",
      agree4: "",
      agree5: "",
      showflag: true,
      superAccts:{},//
      tradeAcco: '',
      balancenew:null,//净值金额
    };
  },
  computed: {
    rate() {
      if (this.matchObj.rate) {
        if (this.matchObj.rate >= 100) {
          return this.matchObj.rate + "元";
        } else {
          return this.matchObj.rate + "%";
        }
      } else {
        return "--";
      }
    },
    discountRate() {
      if (this.matchObj.discountRate) {
        if (this.matchObj.rate >= 100) {
          return this.matchObj.discountRate * this.matchObj.rate / 100 + "元";
        } else {
          return this.matchObj.discountRate * this.matchObj.rate / 100 + "%";
        }
      } else {
        return "--";
      }
    },
    costPrice() {
				if (this.matchObj.rate) {
					if (this.matchObj.discountRate) {
              if (this.matchObj.rate >= 100) {
                return ((this.matchObj.discountRate / 100) * this.matchObj.rate).toFixed(2) + "元";
              } else {
                return (((this.matchObj.discountRate/100)*this.matchObj.rate *this.balancenew /100).toFixed(2) +"元");
              }
            } else {
              if (this.matchObj.rate >= 100) {
                return this.matchObj.rate + "元";
              } else {
                return ((this.matchObj.rate * this.balancenew) / 100).toFixed(2) + "元";
              }
					}
				} else {
					return "--";
				}
				}
  },
  methods: {
    bankswith(o) {
      this.bankindex = o;
      if (o == 2) {
        this.queryBankCarddata(this.funddata.fundCode);
      } else if (o == 1) {
        // this.enableSharedata(this.funddata.fundCode);
        this.getxjbList()
      }
    },
    getDictionaries(val, option) {
      this.getDicts(val).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            // this[option][item.dictValue] = item.dictLabel
            this.$set(this[option], item.dictValue, item.dictLabel);
          });
        }
      });
    },
    /** 获取产品协议 */
    getProtocol() {
      getDicts("protocol_name").then(result => {
        let dicts = result.data || [];
        protPage({ pageSize: 100, fundCode: this.funddata.fundCode }).then(
          result => {
            let data = result.data.data || [];

            let id1 =
              (dicts.find(m => m.dictLabel == "《产品概要》") || {})
                .dictValue || "";
            this.agree1 = (data.find(m => m.protName === id1) || {}).protUrl;
            let id2 =
              (dicts.find(m => m.dictLabel == "《基金合同》") || {})
                .dictValue || "";
            this.agree2 = (data.find(m => m.protName === id2) || {}).protUrl;
            let id3 =
              (dicts.find(m => m.dictLabel == "《招募说明书》") || {})
                .dictValue || "";
            this.agree3 = (data.find(m => m.protName === id3) || {}).protUrl;
            let id4 =
              (dicts.find(m => m.dictLabel == "《风险揭示书》") || {})
                .dictValue || "";
            this.agree4 = (data.find(m => m.protName === id4) || {}).protUrl;
            let id5 =
              (dicts.find(m => m.dictLabel == "《客户维护费揭示书》") || {})
                .dictValue || "";
            this.agree5 = (data.find(m => m.protName === id5) || {}).protUrl;
          }
        );
      });
    },
    showrule() {
      this.$refs.fundRule.show = true;
    },
    getfund(a) {
      baseInfo({
        fundCode: a
      }).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.funddata = res.data;
          if (this.funddata.privateFlag == "1") {
            // this.showflag = true;
            if (res.data.allowRedeemToXjb=='0') {
              this.showflag = false;
              this.bankindex = 2;
            } else {
              this.showflag = true;
              this.bankindex = 1;
            }
            this.queryBankCarddata(this.funddata.fundCode);
            // this.enableSharedata(this.funddata.fundCode);
            this.getxjbList()
          } else {
            if (res.data.allowRedeemToXjb=='0') {
              this.showflag = false;
              this.bankindex = 2;
            } else {
              this.showflag = true;
              this.bankindex = 1;
            }
            this.getxjbList()
            this.queryBankCarddata(this.funddata.fundCode);
            // this.enableSharedata(this.funddata.fundCode);
          }
        }
      });
    },
    enableSharedata(params) {
      queryUserShare(params).then(res => {
        this.tradeAcco = res.data.tradeAcco
        this.shares = res.data.usableShare;
        this.xjbprice = res.data.usableShare;
        // this.banklist = res.data;
        // this.banklist = {
        //   ...this.banklist,
        //   bankAccount: this.banklist.banckNo
        // };
      });
    },
    getsuperAcct(params) {
      queryUserShare(params).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.shares = res.data.usableShare;
          this.bankshare = res.data.usableShare;
          this.tradeAcco = res.data.tradeAcco
        }
      });
    },
    queryBankCarddata(a) {
      queryBankCard({ fundCode: a }).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.palybank = res.data;
          this.banklist = (res.data && res.data[0]) || {};
          let datas = res.data;
          let a = JSON.stringify(datas);
          let b = JSON.parse(a);
          this.play = b.map(item => {
            let str = item.bankAccount.slice(0, 4);
            let str2 = item.bankAccount.slice(-4);
            item.bankAccount = str + " **** **** " + str2;
            return item;
          });
          if (this.cardindex == 0) {
            this.getsuperAcct({
              fundCode: this.funddata.fundCode,
              bankNo: res.data[0].bankAccount,
              xjbFlag: 0
            });
          }
        }
      });
    },
    blurs() {
      let teg = /^[0-9]+.?[0-9]*$/;
      this.numprice=this.numprice.match(/\d+(\.\d{0,2})?/)?this.numprice.match(/\d+(\.\d{0,2})?/)[0]:0;
      this.balancenew=Number(this.numprice)*Number(this.funddata.unitnv).toFixed(2);
      if (parseFloat(this.numprice) > this.shares && teg.test(this.numprice)) {
        this.showti = true;
        return;
      } else {
        this.showti = false;
        rateMagMatch({
          fundCode: this.funddata.fundCode,
          rateType: "3",
          amount: Number(this.numprice),
          tradeAcco: this.tradeAcco
        }).then(r => {
          this.matchObj = r.data.length ? r.data[0] : {};
        });
      }
    },
    clerInput(e) {
      let key = e.key;
      if (key === "e" || key === "E" || key === "-" || key === "+") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    toGos() {
      this.$router.go(-1);
    },
    getcard(obj, i) {
      this.cardindex = i;
      this.banklist = this.palybank[i];
      this.getsuperAcct({
        fundCode: this.funddata.fundCode,
        bankNo: this.banklist.bankAccount,
        xjbFlag: 0
      });
    },
    getcardxjb(obj, i) {
      this.xjbindex = i;
      console.log('--',this.xjbindex)
      this.superAccts = this.xjbList[i]; //切换银行卡
      
      this.findXjbBalance(this.superAccts.tradeAcco)
      this.enableSharedata({
              fundCode: this.funddata.fundCode,
              bankNo: this.superAccts.bankAccount,
              xjbFlag: '0'
        });
    },
    getxjbList(){
      queryBankCard({fundCode:this.funddata.fundCode,xjbFlag:'1'}).then(res => {
        this.superAccts=res.data&&res.data[0]
        this.xjbList = res.data;
        this.xjbindex=0;
        this.findXjbBalance(this.superAccts.tradeAcco);
        this.enableSharedata({
              fundCode: this.funddata.fundCode,
              bankNo: res.data[0].bankAccount,
              xjbFlag: 0
        });
      });
    },
    findXjbBalance(val) {
      findXjbBalance({ tradeAcco: val }).then(result => {
        
      });
    },
    algorithnum(i) {
      this.algorithindex = i;
      if (i === 0) {
        let nums = (this.shares / 4).toFixed(2);
        this.numprice = nums > 0 ? nums : 0;
        // this.numprice = this.shares;
      } else if (i === 1) {
        let nums = (this.shares / 3).toFixed(2);
        this.numprice = nums > 0 ? nums : 0;
        // this.numprice = this.shares;
      } else if (i === 2) {
        let nums = (this.shares / 2).toFixed(2);
        this.numprice = nums > 0 ? nums : 0;
        // this.numprice = this.shares;
      } else {
        this.numprice = this.shares;
      }
      this.balancenew=Number(this.numprice)*Number(this.funddata.unitnv).toFixed(2);
      rateMagMatch({
        fundCode: this.funddata.fundCode,
        rateType: "3",
        amount: Number(this.numprice),
        tradeAcco: this.tradeAcco
      }).then(r => {
        this.matchObj = r.data.length ? r.data[0] : {};
      });
    },
    topeges() {
      if (this.bankindex == 1) {
        let teg = /^[0-9]+.?[0-9]*$/;
        if (
          !this.numprice &&
          !teg.test(this.numprice || this.numprice > this.shares)
        ) {
          this.$message.error("请正确输入卖出金额！");
          return;
        } else if (!this.checkbool) {
          this.$message.error("请阅读并同意基金卖出相关协议！");
        } else {
          let params = {
            fund: this.funddata,
            num: Number(this.numprice),
            shares: this.shares,
            banklist: this.superAccts,
            fundTransferDate:this.matchObj.fundTransferDate,
            tradeAcco : this.tradeAcco,
            type: 1
          };
          this.$router.push({
            name: "SellOutFundPassword",
            params: { params }
          });
        }
      } else if (this.bankindex == 2) {
        let teg = /^[0-9]+.?[0-9]*$/;
        if (
          (!this.numprice && !teg.test(this.numprice)) ||
          this.numprice > this.shares
        ) {
          this.$message.error("请正确输入赎回份额！");
          return;
        } else if (!this.checkbool) {
          this.$message.error("请阅读并同意基金卖出相关协议！");
        } else if (JSON.stringify(this.banklist) == "{}") {
          this.$message.error("请正确添加银行卡");
        } else {
          let params = {
            fund: this.funddata,
            shares: this.shares,
            num: Number(this.numprice),
            banklist: this.banklist,
            fundTransferDate:this.matchObj.fundTransferDate,
            type:2
          };

          this.$router.push({
            name: "SellOutFundPassword",
            params: { params }
          });
        }
      }
    }
  },
  created() {
    this.userinfos =
      JSON.parse(window.localStorage.getItem("customerInfo")) || {};
    let a = sessionStorage.getItem("SmOutfundCode");
    this.getProtocol();
    this.getDictionaries("sys_fund_risklevel", "sys_fund_riskleveldata");
    this.getDictionaries("sys_fund_share_type", "sys_fund_share_typedata");
    this.getDictionaries("sys_fund_type", "sys_fund_typedata");
    if (!this.$route.params.fundCode && a) {
      this.getfund(a);
      this.fundCodes=a;
    } else {
      window.sessionStorage.setItem(
        "SmOutfundCode",
        this.$route.params.fundCode
      );
      this.getfund(this.$route.params.fundCode);
      this.fundCodes=this.$route.params.fundCode;
    }
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}
.fund-rule {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}
.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 40px;
      margin-top: 30px;
      .titles_ {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #1f1f1f;
      }
      .right_btns {
        text-align: right;
        margin-top: 40px;
      }
      .card_new {
        border-radius: 12px;
        box-shadow: 0px 5px 10px 0px #edf1f7;
        margin-top: 12px;
        height: 80px;
        padding: 24px 24px 0 24px;
        width: 802px;
        .card_l {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          .right_ti {
            .nema_ti {
              .num {
                color: #bdc0cb;
                font-size: 18px;
                font-weight: bold;
                margin-right: 10px;
              }
              .name_ {
                color: #1f1f1f;
                font-size: 18px;
              }
            }
            .tags {
              margin-top: 16px;
              display: flex;
              align-items: center;
              .tag_ {
                padding: 6px 20px;
                border-radius: 14px;
                font-size: 12px;
                color: #8691a8;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                border: 1px solid #e0e2e8;
                margin-right: 14px;
                &:first-child {
                  border: none;
                  background: #fff5ea;
                  color: #ce9b63;
                  padding: 7px 20px;
                }
              }
            }
          }
          .r_nums {
            font-size: 14px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #a5a5a5;
            .price_nums {
              font-size: 36px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
              font-weight: 700;
              color: #deb87b;
              margin-left: 11px;
              margin-right: 4px;
            }
            .fen_S {
              color: #deb87b;
            }
          }
          .right_btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: 30px;
            span {
              font-size: 12px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #000000;
            }
            img {
              width: 12px;
              height: 12px;
              margin-right: 12px;
            }
          }
        }
      }
    }
    .play_type {
      padding: 0px 40px;
      margin-top: 40px;
      .ti_ {
        font-size: 16px;
        font-family: ABeeZee, ABeeZee-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #1f1f1f;
        margin-bottom: 14px;
      }
      .bank_card {
        .bank_list {
          width: 468px;
          height: 46px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          position: relative;
          margin-bottom: 24px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          .l_imgs {
            width: 26px;
            height: 26px;
            margin-right: 19px;
          }
          .c_name {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            text-align: LEFT;
            color: #25293d;
            width: 127px;
          }
          .c_nameac {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #25293d;
          }
          .r_share {
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #25293d;
            .color_ {
              color: #ce9b63;
            }
          }
          .right_true {
            position: absolute;
            right: -16.5px;
            top: -16.5px;
            width: 0px;
            height: 0px;
            border: 16px solid #f7d9b7;
            border-color: transparent transparent #eabf96 transparent;
            transform: rotate(45deg);
            // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
            span {
              color: #fff;
              position: absolute;
              transform: rotate(-35deg);
              top: -1px;
              right: -5px;
            }
          }
        }
        .bank_listac {
          border: 1px solid;
          border-image: linear-gradient(95deg, #f7d9b7 0%, #eabf96 100%) 1 1;
          border-radius: 4px;
        }
        .bankcard_ {
          margin-bottom: 30px;
          padding: 30px 24px 6px 24px;
          background: #f6f5f3;
          display: flex;
          border-radius: 2px;
          // align-items: center;
          // justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
          border-radius: 4px;

          .backs_ac {
            width: 208px !important;
            border: 1px solid #ce9b63;
            height: 54px !important;
            border-radius: 4px;
          }
          .banks_ {
            padding: 22px 20px;
            width: 210px;
            height: 56px;
            background: #fff;
            margin-right: 24px;
            margin-bottom: 24px;
            border-radius: 4px;

            position: relative;
            &:nth-child(3n + 3) {
              margin-right: 0px;
            }
            .right_true {
              position: absolute;
              right: -16.5px;
              top: -16.5px;
              width: 0px;
              height: 0px;
              border: 16px solid #f7d9b7;
              border-color: transparent transparent #eabf96 transparent;
              transform: rotate(45deg);
              // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
              span {
                color: #fff;
                position: absolute;
                transform: rotate(-35deg);
                top: -1px;
                right: -5px;
              }
            }
            .top_ban {
              display: flex;
              align-items: center;
              img {
                width: 22px;
                height: 22px;
                margin-right: 7px;
              }
              .bank_name {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }
            }
            .cardnum_ {
              margin-top: 20px;
              font-size: 18px;
              font-family: Bahnschrift, Bahnschrift-Light;
              font-weight: 300;
              color: #1f1f1f;
            }
          }
          .addbank_ {
            width: 249px;
            height: 98px;
            border: 1px dashed #bdc0cb;
            border-radius: 4px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            .add_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #bdc0cb;
            }
          }
        }
      }
      .input_share {
        .ti_ {
          font-size: 16px;
          font-family: ABeeZee, ABeeZee-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #1f1f1f;
          margin-bottom: 14px;
        }
        .input_box {
          display: flex;
          align-items: center;
          .input_ {
            display: flex;
            align-items: center;
            padding: 0px 0px 0px 24px;
            justify-content: space-between;
            border: 1px solid #e0e2e8;
            border-radius: 2px;
            width: 470px;
            height: 46px;
            .long {
              font-size: 14px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ce9b63;
            }
          }
          .fen_s {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: LEFT;
            color: #1f1f1f;
            margin-right: 14px;
          }
          .rules_btn {
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #ce9b63;
          }
        }
        .red_ti {
          margin-top: 19px;
          color: red;
          font-size: 12px;
        }
        .list_nums_ {
          margin-top: 30px;
          display: flex;
          align-items: center;
          .btn_s {
            display: inline-block;
            padding: 6px 20px;
            border: 1px solid #e0e2e8;
            border-radius: 14px;
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #ce9b63;
            margin-right: 14px;
          }
          .btn_sac {
            background: #deb87b;
            color: #fff !important;
          }
        }
        .rule_txt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 64px;
          padding: 0px 14px;
          background: rgba(255, 245, 234, 0.5);
          border-radius: 2px;
          margin-top: 30px;
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;
          .rule_ {
            margin-top: 4px;
          }
        }
        .rule_file {
          margin-top: 30px;
          .file_ti {
            display: flex;
            align-items: center;
            .file_img_ti {
              width: 22px;
              height: 22px;
              margin-right: 3px;
            }
            .file_txt_ti {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #1f1f1f;
              .file_btn {
                color: #ce9b63;
              }
            }
          }
          .files_ {
            margin-top: 17px;
            display: flex;

            .files_btns {
              width: 500px;
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #1f1f1f;
              .tobtn_ {
                color: #ce9b63;
              }
            }
          }
          .foot_btns {
            display: flex;
            margin-top: 22px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 70px;
            .left_num {
              .num_ti {
                font-size: 12px;
                font-family: Poppins, Poppins-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }
              .nums_ {
                font-size: 26px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
                font-weight: 700;
                color: #e45247;
                .fen_ {
                  font-size: 12px;
                  font-family: Poppins, Poppins-Regular;
                  font-weight: 400;
                  color: #1f1f1f;
                  margin-left: 10px;
                }
              }
            }
            .right_btn {
              display: inline-block;
              background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
              border-radius: 2px;
              height: 48px;
              line-height: 48px;
              text-align: center;
              width: 200px;
              color: #b88141;
            }
          }
        }
      }
    }
  }
}
</style>
